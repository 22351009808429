import { template as template_4ee74a82cf9e446e9cf59c2571094301 } from "@ember/template-compiler";
const WelcomeHeader = template_4ee74a82cf9e446e9cf59c2571094301(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
