import { template as template_f73d6a5153064db5bbca70bce4f1e710 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f73d6a5153064db5bbca70bce4f1e710(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
