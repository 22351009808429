import { template as template_225363e9fee04e9ba39d7d722f94d2ac } from "@ember/template-compiler";
const SidebarSectionMessage = template_225363e9fee04e9ba39d7d722f94d2ac(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
