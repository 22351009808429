import { template as template_cdc6f77c8c2f4f37bd1dfdb1118cc1a6 } from "@ember/template-compiler";
const FKLabel = template_cdc6f77c8c2f4f37bd1dfdb1118cc1a6(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
