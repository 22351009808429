import { template as template_af71e45a89794fd8936f3042836e19a9 } from "@ember/template-compiler";
const FKText = template_af71e45a89794fd8936f3042836e19a9(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
